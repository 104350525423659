import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.jsx';
import './assets/styling/index.css';
import { loadableReady } from '@loadable/component';


async function getInitialData() {
  let initialData = { articles: [] };
  try {
    const portVar = process.env.VITE_FETCH_FETCH_PORT;
    const hostVar = process.env.VITE_FETCH_FE_HOST;
    const endpointVar = process.env.VITE_FETCH_LOCATION;
    const apiUrl = `http://${hostVar}:${portVar}/${endpointVar}`;
    const apiResponse = await fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: 78912, page: 1, limit: 10 }),
    });
    initialData = await apiResponse.json();

  } catch (error) {
    initialData.error = true;
  }
  return initialData;
}


loadableReady(() => {
  const initialData = window.__INITIAL_DATA__;
  hydrateRoot(document.getElementById('root'), (
    <React.StrictMode>
      <BrowserRouter>
        <App cardData={initialData} />
      </BrowserRouter>
    </React.StrictMode>
  ));
});