import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home.jsx';
import Sources from './Pages/Sources.jsx';
import About from './Pages/About.jsx';


function App({ cardData }) {

  return (
    <>
      <Routes>
        <Route path="/" element={<Home cardData={cardData} />} />
        <Route path="/sources" element={<Sources />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  )
}


export default App


