// import './../App.css'
import Navbar from '../components/Navbar.jsx';


function Sources() {
    return (
        <>
            <div className="infopages">
                <h1 className="infopages" id="maintitle">Sources</h1>
                <p>You might be wondering: "Which newspapers were chosen for this website and why?" Well, there's your answer: they covered different topics and had some level of popularity accross different locations.
                </p>
            </div>
            <Navbar />

        </>
    )
}


export default Sources