// import './../../public/assets/styling/App.css'
// import fs from 'fs/promises';
// import path from 'path';
import React from 'react';
import Navbar from '../components/Navbar.jsx';
// import useFetch from "react-fetch-hook";
// import { lazy, Suspense } from 'react';
// import { fileURLToPath } from 'url';
import loadable from '@loadable/component';
// import { createRequire } from 'module';
// const require = createRequire(import.meta.url);

function Mainbutton() {
    const handleClick = (e) => {
        e.preventDefault(); // Prevent default anchor link behavior

        // Scroll to the element with ID #Cards smoothly
        document.querySelector('#Cards').scrollIntoView({
            behavior: 'smooth'
        });
    };

    return (
        <a href="#Cards" className="mainbuttonfill" onClick={handleClick}>
            <div className="mainbuttontext">Click here</div>
        </a>
    );
}

function Secondarybutton() {
    return (
        <a href="/about" className="secondarybuttonfill">
            <div className="secondarybuttontext" >Learn more</div>
        </a>
    )
}

function Landingleft() {
    return (
        <div className="landingleft" >
            <h1 className="lefthand" id="maintitle">All the news in one place</h1>
            <p className="lefthand" id="calltoaction"> Why stick with one newspaper? Find the latest news from multiple sources, all in one platform</p>
            <div className="landingbuttons">
                <Mainbutton />
                <Secondarybutton />
            </div>
        </div>
    )
}

function Landingright() {
    return (
        <div className="landingright">
        </div>
    )
}


// const portVar = 3001
// const hostVar = "fetch_c"
// const endpointVar = "/articles/article"

// const portVar = import.meta.env.VITE_FETCH_FETCH_PORT;
// const hostVar = import.meta.env.VITE_FETCH_FE_HOST;
// const endpointVar = import.meta.env.VITE_FETCH_LOCATION;

// function UseFetchData({ render }) {
//     const { data, isLoading, error } = useFetch(
//         `http://${hostVar}:${portVar}/${endpointVar}`,
//         {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             body: '{"id": 78912, "page": 1, "limit": 10}',
//             credentials: "omit"
//         }
//     );

//     if (isLoading) {
//         return <h1>Loading...</h1>;
//     }

//     if (error) {
//         return <div className="error">Something went wrong: {error.toString()}</div>;
//     }

//     return render(data);
// }



const ContentCard = loadable(() =>
    import("./../components/Card.jsx").then((module) => module.default)
);


// ContentCard.preload(); // Preload the component for better performance

function Home({ cardData }) {


    return (
        <>
            <div className="landing">
                <Landingright />
                <Landingleft />
            </div>
            {/* <Suspense> */}
            <ContentCard fallback={<div>Loading articles...</div>} cardData={cardData} />
            {/* </Suspense> */}
            <Navbar />
        </>
    )
}


export default Home