const tudoLogo = '/assets/logo.png'

function Navbar() {
    return (
        <>
            <div className="navbar">
                <div className='logowithslogan'>
                    <img
                        src={tudoLogo}
                        alt="Tudo logo"
                        className="barlogo"
                        id="barlogo"
                    />
                    <span className="slogan">Where stories gather</span>
                </div>
                <div className="routes">
                    <a href="/" id="Home" className="barlink">Home</a>
                    <a href="/sources" id="Sources" className="barlink">Sources</a>
                    <a href="/about" id="About" className="barlink">About</a>
                </div>
            </div>
        </>
    );
}

export default Navbar