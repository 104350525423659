// import './assets/styling/App.css'
import Navbar from '../components/Navbar.jsx';


function About() {
    return (
        <>
            <div className="infopages">
                <h1 className="infopages" id="maintitle">About</h1>
                <p>What is this page? What are these articles? What is the purpose of all this? Well, you were looking for answers for these questions and you can find the answers here!
                    This all started as a side-project for learning purposes. Yet, now it is a gradually improving platform. As for what to be expected from this website, this is a news aggregator platform, where articles linking to different sources can be found, along with links to their sources. This will allow you to find articles from multiple sources from one same platform.
                </p>
            </div>
            <Navbar />

        </>
    )
}

export default About